import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { BreakpointProvider } from 'react-socks'
import { Flex, Box } from '@rebass/grid'

import Footer from '../components/footer'
import Head from '../components/head'
import Navbar from '../components/Navbar'
import Section from '../components/Section'
import { useStaticQuery, graphql } from 'gatsby'

const PAGE_TITLE = 'Hubtype - Cookies Policy';

const Title = styled.h1`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 45px;
  color: #ffffff;
  margin-bottom: -38px;
`
const Subtitle = styled.h3`
  font-family: Heebo;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 35px; /* identical to box height */

  color: #333333;
`
const Paragraph = styled.p`
  font-family: Heebo;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px; /* identical to box height */

  color: #333333;
`
const CookiesPolicy = props => {
  const { bgHeader } = useStaticQuery(
    graphql`
      query {
        bgHeader: file(relativePath: { eq: "home/bg_header_cookies.png" }) {
          childImageSharp {
            fluid(maxWidth: 2048) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )
  let bg = (
    <Img
      fluid={bgHeader.childImageSharp.fluid}
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: -1,
      }}
    />
  )
  return (
    <BreakpointProvider>
      <Head
        alternates={props.pageContext.i18n}
        autopilot={props.pageContext.autopilot}
        lang={props.pageContext.lang}
        title={PAGE_TITLE}
        uri={props.pageContext.relativeUrl}
      />
      <Flex flexDirection="column" css={{ overflow: 'hidden' }}>
        <Navbar
          theme="dark"
          style={{
            background: 'linear-gradient(180deg, #333333 0%, #6500CB 1266.15%)',
            borderBottom: 'none',
          }}
          {...props}
        />
        <Section py={120} background={bg}>
          <Flex ml={[3, 0]} mt={3}>
            <Title>Cookies Policy</Title>
          </Flex>
        </Section>
        <Flex flexDirection="column" style={{ background: 'white' }}>
          <Section>
            <Flex
              flexDirection="column"
              width={['80%', '65%']}
              ml={[3, 0]}
              mt={5}
            >
              <Subtitle>Personal Data Protection</Subtitle>
              <Box>
                <Paragraph>
                  In accordance with the law on protection of personal data
                  currently in force, METIS SOLUTIONS, S.L., with corporate
                  address at C/ Veneçuela, 105, 4th floor 08019 Barcelona (the{' '}
                  <b>“Company”</b>
                  ), hereby informs you that all the personal data provided by
                  you or accessed by the Company within your browsing, queries
                  and/or applications through the website{' '}
                  <a
                    href="https://www.hubtype.com"
                    style={{ color: '#FF2B5E' }}
                  >
                    www.hubtype.com
                  </a>{' '}
                  (the <b>“Website”</b>) will be included on a file owned by the
                  Company for the purpose of managing your information
                  requirements and, as the case may be, to send commercial
                  information, also by electronic means.
                </Paragraph>
              </Box>
              <Box>
                <Paragraph>
                  It will be necessary to fill in all the fields requested as
                  “compulsory” in the sections included in the Website. Should
                  you fail to complete or partially complete the personal data
                  requested, the Company may not be able to attend your request
                  and, accordingly, the Company will not be liable for not
                  rendering, or rendering incompletely, the services requested.
                </Paragraph>
              </Box>
              <Box>
                <Paragraph>
                  You will be responsible for the veracity of the data provided.
                </Paragraph>
              </Box>
              <Box>
                <Paragraph>
                  The Company has the necessary technical and organizational
                  security safeguards in place to guarantee the security of
                  personal data and to prevent any alteration, loss, processing
                  of, and/or unauthorized access to, them, taking into account
                  the state of the technology, the type of data stored and the
                  risks to which they are exposed, whether they arise from human
                  actions or from the physical or natural environment, in
                  accordance with the provisions of law in force. You may
                  exercise your rights to access, rectify, terminate and oppose
                  the processing of data at the Company’s corporate address
                  under the terms set forth in law in force. For more
                  convenience, however, and notwithstanding the fact that
                  certain formal requirements set out in the law in force must
                  be met, the Company offers the user the opportunity to
                  exercise the above rights, as well as to revoke the consent
                  granted for the commercial communications, by sending an email
                  to{' '}
                  <a
                    href="https://www.hubtype.com/contact"
                    style={{ color: '#FF2B5E' }}
                  >
                    hi@hubtype.com
                  </a>
                  .
                </Paragraph>
              </Box>
            </Flex>
            <Flex
              flexDirection="column"
              width={['80%', '65%']}
              ml={[3, 0]}
              mt={5}
            >
              <Subtitle>Cookies</Subtitle>
              <Box>
                <Paragraph>
                  By accessing and using the Website, you consent to the Company
                  to use own and third party cookies (specially, …) in a session
                  and persistent form for the purpose of allow browsing through
                  the Website and ….
                </Paragraph>
              </Box>
              <Box>
                <Paragraph>
                  Cookies are automatic procedures that collect information
                  relating to specific preferences by Internet users during
                  their visit to a specific website, with the purpose of, among
                  others, recognize them as recurrent users and personalize
                  their use and facilitate a faster navigation.
                </Paragraph>
              </Box>
              <Box>
                <Paragraph>
                  You may configure your browser to accept or reject by default
                  all the cookies, or to receive a warning on the screen about
                  the reception of each cookie and decide at that moment its
                  implementation or otherwise.
                </Paragraph>
              </Box>
              <Box>
                <Paragraph>
                  The procedures to block and delete cookies may differ from one
                  Internet browser to the other, so YOU will have to review the
                  instructions provided by the Internet browser, such as the
                  following:
                </Paragraph>
              </Box>
            </Flex>
            <Flex
              flexDirection="column"
              width={['80%', '65%']}
              ml={[3, 0]}
              mt={5}
            >
              <Box ml={4}>
                <Box>
                  <Paragraph style={{ color: '#FF2B5E' }}>
                    Configuration of Internet Explorer cookies
                  </Paragraph>
                </Box>
                <Box>
                  <Paragraph style={{ color: '#FF2B5E' }}>
                    Configuration of Firefox cookies
                  </Paragraph>
                </Box>
                <Box>
                  <Paragraph style={{ color: '#FF2B5E' }}>
                    Configuration of Google Chrome cookies
                  </Paragraph>
                </Box>
                <Box>
                  <Paragraph style={{ color: '#FF2B5E' }}>
                    Configuration of Safari cookies
                  </Paragraph>
                </Box>
              </Box>
              <Box mt={2}>
                <Paragraph>
                  These browsers are subject to upgrades or modifications so we
                  cannot guarantee that they are totally adapted to the browser
                  version.
                </Paragraph>
              </Box>
              <Box mt={2}>
                <Paragraph>
                  If you decide against the use of cookies, you may have limited
                  access to some of the services of the Website. If you continue
                  browsing through the Website without refusing them, it implies
                  that you accept their use by the Company.
                </Paragraph>
              </Box>
              <Box mt={2} mb={5}>
                <Paragraph>
                  For any additional question regarding the use of cookies, the
                  user can send a request by e-mail to{' '}
                  <a
                    href="https://www.hubtype.com/contact"
                    style={{ color: '#FF2B5E' }}
                  >
                    hi@hubtype.com
                  </a>
                  .
                </Paragraph>
              </Box>
            </Flex>
          </Section>
          <Footer {...props} />
        </Flex>
      </Flex>
    </BreakpointProvider>
  )
}

export default CookiesPolicy
